import React, { useEffect, useState, Link } from "react";
import "./DiversityAndEquality.scss";
import Loader from "../loader/loader";
import Pdf_shqip from "./pdf/Shqip.pdf";
import Pdf_Ang from "./pdf/ANG.pdf";
import Pdf_srb from "./pdf/SRB.pdf";
import { connect } from "react-redux";
import axios from "../../axios";

import "./../Laws/Laws.scss";
const DiversityAndEquality = (props) => {
  console.log("propsat", props);

  const [documents, setDocuments] = useState([]);
  const [iSloading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => setLoading(false), 200);
  }, []);

  const translateMe = (key, langId) => {
    let diqka = props?.langData?.filter((el) => el.key == key)[0];
    if (langId === "al") {
      return diqka?.content_al;
    }
    if (langId === "sr") {
      return diqka?.content_sr;
    }
    if (langId === "en") {
      return diqka?.content_en;
    }
  };

  useEffect(() => {
    axios
      .get("admin/documents_publications/all")
      .then((res) => {
        setDocuments(res.data);
        setTimeout(() => setLoading(false), 500);
      })
      .catch((err) => console.log("error", err));
  }, []);

  console.log("testtest", documents);
  return (
    <>
      {iSloading == true ? (
        <Loader />
      ) : (
        <div className="container diversity">
          <div className="row">
            <h3 className="my-5 text-center">
              {translateMe("diversityAndEquality", props?.landId)}
            </h3>

            {documents?.map((e) => {
              const title =
                props?.landId === "al"
                  ? e.title_al
                  : props?.landId === "en"
                  ? e.title_en
                  : e.title_sr;
              const description =
                props?.landId === "al"
                  ? e.description_al
                  : props?.landId === "en"
                  ? e.description_en
                  : e.description_sr;
              const pdfLink =
                props?.landId === "al"
                  ? e.pdf_link_al
                  : props?.landId === "en"
                  ? e.pdf_link_en
                  : e.pdf_link_sr;

              return (
                <div className="col-lg-4 col-md-6 col-12 mb-4" key={e.id}>
                  <a
                    href={process.env.REACT_APP_UPLOADS + pdfLink}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="card-link"
                  >
                    <div className="shadow p-4">
                      <h5
                        className="card-title mt-2"
                        dangerouslySetInnerHTML={{ __html: title }}
                      ></h5>
                      <div className="card-body text-single">
                        {description && (
                          <p
                            dangerouslySetInnerHTML={{ __html: description }}
                          ></p>
                        )}
                      </div>
                      <div className="row justify-content-end">
                        <div className="arrowbg text-center py-2">
                          <i className="fa fa-arrow-right text-light"></i>
                        </div>
                      </div>
                    </div>
                  </a>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    // loggedIn: state.data.loggedIn,
    // reportData: state.data.reportData,
    landId: state.data.langId,
    langData: state.data.langData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    // logMeIn: () => dispatch(logMeIn()),
    // setReportData: (data) => dispatch(setReportData(data)),
    // Clear: () => dispatch(Clear()),
    // setLangID: (id) => dispatch(setLangID(id)),
    // setLangData: (data) => dispatch(setLangData(data)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DiversityAndEquality);
