import React, { useEffect, useState } from "react";
import "./mision.scss";
import axios from "../../axios";
import Loader from "../loader/loader";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";
import {
  FacebookIcon,
  TwitterIcon,
  FacebookShareButton,
  TwitterShareButton,
  LinkedinIcon,
  LinkedinShareButton,
} from "react-share";

const Mision = (props) => {
  const [mision, setMision] = useState([]);
  const [isLoading, setLoading] = useState(true);

  const translateMe = (key, langId) => {
    let diqka = props?.langData?.find((el) => el.key === key);
    switch (langId) {
      case "al":
        return diqka?.content_al;
      case "sr":
        return diqka?.content_sr;
      case "en":
        return diqka?.content_en;
      default:
        return "";
    }
  };

  useEffect(() => {
    axios
      .get("general/mision")
      .then((res) => {
        setMision(res.data);
        setLoading(false);
      })
      .catch((err) => console.log("error", err));
  }, []);

  const location = useLocation();
  const { pathname } = location;
  const shareUrl = `https://raportodiskriminimin.org/${pathname}`;

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <div className="container">

        <div className="mission-section row justify-content-center">
            <div className="col-md-10 col-12">
            <h1 className="text-center mt-5">
              {translateMe("missionAndVision", props?.landId)}
            </h1>
            {mision.map((e, index) => (
              <div key={index} className="content-card fw-lighter">
                <p dangerouslySetInnerHTML={{ __html: e[`content_${props.landId}`] }}></p>
              </div>
            ))}
          </div>
          <div className="social-icons my-4">
            <FacebookShareButton url={shareUrl}>
              <FacebookIcon size={40} round />
            </FacebookShareButton>
            <TwitterShareButton url={shareUrl}>
              <TwitterIcon size={40} round />
            </TwitterShareButton>
            <LinkedinShareButton url={shareUrl}>
              <LinkedinIcon size={40} round />
            </LinkedinShareButton>
          </div>
        </div>
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  landId: state.data.langId,
  langData: state.data.langData,
});

export default connect(mapStateToProps)(Mision);
