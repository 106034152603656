import React, { useEffect, useState } from "react";
import "./LawsNationality.scss";
import axios from "../../axios";
import Loader from "../loader/loader";
import { connect } from "react-redux";

const LawsInternacionality = (props) => {
  const [lawsInternacional, setLawsInternacionality] = useState([]);
  const [isLoading, setLoading] = useState(true);

  const translateMe = (key, langId) => {
    const translation = props?.langData?.find((el) => el.key === key);
    switch (langId) {
      case "al":
        return translation?.content_al;
      case "sr":
        return translation?.content_sr;
      case "en":
        return translation?.content_en;
      default:
        return "";
    }
  };

  useEffect(() => {
    axios
      .get("/general/laws/all/international")
      .then((res) => {
        setLawsInternacionality(res.data);
        setTimeout(() => setLoading(false), 500);
      })
      .catch((err) => console.log("error", err));
  }, []);

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <div className="laws-container container">
          <h1 className="text-center my-4">
            {translateMe("mainMenuLawsOpt2", props?.landId)}
          </h1>
          <div className="accordion-container">
            {lawsInternacional?.map((e, index) => {
              const title = e[`name_${props?.landId}`];
              const description = e[`desc_${props?.landId}`];
              const url = e[`url_${props?.landId}`];

              return (
                <div key={e.id} className="accordion-item">
                  <button
                    className="accordion-button"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target={`#collapse${e.id}`}
                    aria-expanded="false"
                    aria-controls={`collapse${e.id}`}
                  >
                    <div className="icon-wrapper">
                      <i className="fa fa-balance-scale" aria-hidden="true"></i>
                    </div>
                    {title}
                  </button>
                  <div
                    id={`collapse${e.id}`}
                    className="accordion-collapse collapse"
                  >
                    <div className="accordion-body">
                      {description && (
                        <p dangerouslySetInnerHTML={{ __html: description }}></p>
                      )}
                      <div className="url_created">
                        <p>
                          <a href={url} target="_blank" rel="noopener noreferrer">
                            {props?.landId === "al" ? "Lexo më shumë" : "Read more"}
                          </a>
                          <small className="float-end me-5">
                            {translateMe("dateOfPublication", props?.landId)}{" "}
                            {e.leg_date.slice(0, 10)}
                            <i className="float-end ms-2 mt-1 fa fa-calendar"></i>
                          </small>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  landId: state.data.langId,
  langData: state.data.langData,
});

export default connect(mapStateToProps)(LawsInternacionality);
