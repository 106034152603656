import React from "react";
import { useState, useEffect } from "react";
import axios from "../../axios";
import MenuItems from "./data/menuitems";
import './headerNewDesign.scss'
import { Link } from "react-router-dom";
import Login from "../login/login";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Logo from "./images/Logoshqip.png";
import rks from "./images/rks.png";
import News from "../News/News";
import mision from "../mision/mision";
import Mobile from "./mobile";
import { useLocation } from "react-router-dom";
import { connect } from "react-redux";
import { setLangID, setLangData } from "../../redux/Functions/actions";
import albFlag from "./images/albFlag.png"
import engFlag from "./images/engFlag.png"
import srbFlag from "./images/srbFlag.png"
import ReactFlagsSelect from "react-flags-select";
toast.configure();
const HeaderNewDesign = (props) => {
  const [user, setUser] = useState();
  const [active, setActive] = useState();
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  // Handle window resize events
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);
    
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const removeItems = () => {
    window.localStorage.removeItem("token");
    window.localStorage.removeItem("id");
    window.localStorage.clear();
    setTimeout(() => { }, 300);
  };

  // jena duke i mar krejt labelat edhe tu i atachu ne redux s
  // tate qe me mujt me pase akses nga krej applikacioni
  const getTranslations = () => {
    axios
      .get("/general/translations/web/all")
      .then((data) => {
        // console.log("translationData", data.data);
        props.setLangData(data.data);
      })
      .catch((err) => console.log(err));
  };

  // ky eshte funkcioni i cili varet nga ndryshimi i gjuhes dhe ne baze te saj
  // dhe key qe jemi duke kerkuar na kthen contentin e duhur
  const translateMe = (key, langId) => {
    let diqka = props?.langData?.filter((el) => el.key == key)[0];
    if (langId === "al") {
      return diqka?.content_al;
    }
    if (langId === "sr") {
      return diqka?.content_sr;
    }
    if (langId === "en") {
      return diqka?.content_en;
    }
  };
  // console.log("LANGUAGEID",props?.landId)

  // funkcioni me posht e ndryshon statin e gjuhes ne redux
  //nga i cili ndryshon edhe qfar kthejne perkthimet
  const handleLangChange = (value) => {
    let langId;
    
    // Determine the language ID based on the input
    if (value === "AL" || value === "al") langId = "al";
    if (value === "US" || value === "en") langId = "en";
    if (value === "RS" || value === "sr") langId = "sr";
  
    // Update the Redux store with the new language ID
    props.setLangID(langId);
  };
  useEffect(() => {
    getTranslations();
    axios
      .get("/mobile/user/" + window.localStorage.getItem("id"), {
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        setUser(response);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  const location = useLocation();
  const { pathname } = location;
  const splitLocation = pathname.split("/");

  return (
    <>
      <div className="header-section">
        <div className="header-part1">
          <div className="container">
            <div className="part1 p-md-2 p-1">
              <div className="link d-flex align-items-center">
              {isMobile &&  <Link to="/report" className="btn main-button">
                  {translateMe("menuHeaderReportButton", props?.landId)}
                  {/* Report a case */}
                </Link>
              }
                <Link  className=" headerLink"
                  to={"/profile/" + window.localStorage.getItem("id")}
                  style={{
                    display:
                      localStorage.getItem("token") !== null ? "" : "none",
                  }}
                >
                  {user?.data?.username}
                </Link>
                <Link to="Manuals" className=" headerLink">
                  {translateMe("topMenuManuals", props?.landId)}{" "}
                </Link>
                <a href="/signup">{translateMe("signup", props?.landId)}</a>
                <Link
                  to=""
                  data-bs-toggle="modal"
                  data-bs-target="#login-or-register"
                  style={{
                    display:
                      localStorage.getItem("token") !== null ? "none" : "d-flex",
                  }}
                   className=" headerLink"
                >
                  {translateMe("login", props?.landId)}
                </Link>
                <Link
                  to="/"
                  onClick={removeItems}
                  style={{
                    display:
                      localStorage.getItem("token") !== null ? "" : "none",
                  }}
                   className=" headerLink"
                >
                  {translateMe("topMenuLogOut", props?.landId)}
                </Link>
            
                <ReactFlagsSelect
                      countries={["AL", "US", "RS"]}
                      customLabels={{
                        AL: "Albanian",
                        US: "English",
                        RS: "Serbian",
                      }}
                      selected={
                        props?.landId === "al"
                          ? "AL"
                          : props?.landId === "en"
                          ? "US"
                          : "RS"
                      }
                      onSelect={handleLangChange}
                      placeholder="Select Language"
                      showSelectedLabel={false} // Hide the selected country name
                      showOptionLabel={false} // Show labels in the dropdown
                      className="custom-flag-select" // Apply custom CSS class
                    />
                </div>
            </div>
          </div>
        </div>
        {!isMobile ? (
  
        <div className="header" id="myHeader">
          <div className="header-part2  px-md-2 px-1">
            <div className="container">
              <div className="part2 ">
                  <Link to="/">
                <div className="logo">
                    <img src={Logo} alt="logo" />
                </div>
                  </Link>
                <div className="part2-items">
                  <Link
                    to="/"
                    className="text-decoration-none mx-md-1 mx-1 px-md-2"
                  >
                <li className={splitLocation[1] === "" ? "active" : "d-flex"}>
                    {translateMe("mainMenuHome", props?.landId)}

                    {/* Home */}
                </li>
                  </Link>
                  <Link
                    to="/news"
                    className="text-decoration-none mx-md-1 mx-1 px-md-2"
                  >
                <li className={splitLocation[1] === "news" ? "active" : "d-flex"}>
                    {translateMe("mainMenuNews", props?.landId)}

                    {/* News */}
                </li>
                  </Link>
                  <Link
                    to="/aboutus"
                    className="text-decoration-none mx-md-1 mx-1 px-md-2"
                  >
                <li className={splitLocation[1] === "aboutus" ? "active" : "d-flex"}>
                    {translateMe("mainMenuAboutUs", props?.landId)}

                    {/* About Us */}
                </li>
                  </Link>

                  <Link
                    to="/Mision"
                    className="text-decoration-none mx-md-1 mx-1 px-md-2"
                  >
                <li className={splitLocation[1] === "Mision" ? "active" : "d-flex"}>
                    {translateMe("mainMenuMission", props?.landId)}

                    {/* Mission */}
                </li>
                  </Link>
                {/* 
                <li className={splitLocation[1] === "events" ? "active" : "d-flex"}>
                  <Link
                    to="/events"
                    className="text-decoration-none mx-md-1 mx-1 px-md-2"
                  >
                    {translateMe("mainMenuEvents", props?.landId)}

                  </Link>
                </li> */}

                <div className="dropdown d-flex align-items-center">
                  <button
                    className="btn"
                    type="button"
                    id="dropdownMenuButton1"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                    style={{ backgroundColor: "transparent", border: "none", whiteSpace: "pre",}
                  }
                  >
                    {translateMe("mainMenuLaws", props?.landId)}

                    {/* Laws */}
                    <i className="fa fa-caret-down ms-md-2 ms-1"></i>
                  </button>
                  <ul
                    className="dropdown-menu drop_background"
                    aria-labelledby="dropdownMenuButton1"
                  >
                      <Link
                        to="/LawsNationality"
                        className="text-decoration-none mx-md-0 mx-2"
                      >
                    <li>
                        {translateMe("mainMenuLawsOpt1", props?.landId)}

                        {/* Nacional Laws */}
                    </li>
                      </Link>
                      <Link
                        to="/LawsInternacionality"
                        className="text-decoration-none mx-md-0 mx-2"
                      >
                    <li>
                      {" "}
                        {translateMe("mainMenuLawsOpt2", props?.landId)}

                        {/* Internacional Laws */}
                    </li>
                      </Link>
                      <Link
                        to="/Constitution"
                        className="text-decoration-none mx-md-1 mx-1 px-md-2"
                      >
                    <li>
                      {" "}
                        {translateMe("mainMenuLawsOpt3", props?.landId)}

                        {/* Constitution */}
                    </li>
                      </Link>
                  </ul>
                </div>
                <Link
                  to="/DiversityAndEquality"
                  className="text-decoration-none mx-md-1 mx-1 px-md-2"
                >
                <li
                  className={
                    splitLocation[1] === "DiversityAndEquality" ? "active" : "d-flex"
                  }
                >
                    {translateMe("topMenuDiversity", props?.landId)}

                    {/* {translateMe("mainMenuLawsOpt3", props?.landId)} */}

                    {/* Constitution */}
                </li>
                  </Link>
                  <Link
                    to="/protection"
                    className="text-decoration-none mx-md-1 mx-1 px-md-2"
                  >
                <li
                  className={splitLocation[1] === "protection" ? "active" : "d-flex"}
                >
                    {translateMe("mainMenuProtection", props?.landId)}

                    {/* Protection against discrimination */}
                </li>
                  </Link>
              </div>

                <Link to="/report" className="btn main-button">
                  {translateMe("menuHeaderReportButton", props?.landId)}
                  {/* Report a case */}
                </Link>
                <div
                  className="modal fade"
                  id="login-or-register"
                  tabIndex="-1"
                  aria-labelledby="exampleModalLabel"
                  aria-hidden="true"
                >
                  <div className="modal-dialog">
                    <div className="modal-content">
                      <div className="modal-body">
                        <Login />
                      </div>
                      <div className="modal-footer"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="header-part2-items">
            <div className="container">
             
            </div>
          </div> */}
        </div>):
      (  <Mobile />)}
      </div >
    </>
  );
};
// document.addEventListener('DOMContentLoaded', function() {
// window.onscroll = function() {myFunction()};
// var header = document.getElementById("myHeader");
// var sticky = header.offsetTop;

// function myFunction() {
//   if (window.pageYOffset >= sticky) {
//     header.classList.add("sticky");
//   } else {
//     header.classList.remove("sticky");
//   }
// }
// })

const mapStateToProps = (state) => {
  return {
    // loggedIn: state.data.loggedIn,
    reportData: state.data.reportData,
    landId: state.data.langId,
    langData: state.data.langData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    // logMeIn: () => dispatch(logMeIn()),
    // setReportData: (data) => dispatch(setReportData(data)),
    // Clear: () => dispatch(Clear()),
    setLangID: (id) => dispatch(setLangID(id)),
    setLangData: (data) => dispatch(setLangData(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(HeaderNewDesign);