import React, { useState, useEffect } from "react";
import "./News.scss";
import { Link } from "react-router-dom";
import axios from "../../axios";
import { connect } from "react-redux";
import {
  FacebookIcon,
  TwitterIcon,
  FacebookShareButton,
  TwitterShareButton,
} from "react-share";

const News = (propss) => {
  const [news, setnews] = useState([]);
  const shareUrl = "https://raportodiskriminimin.org/";
  // const title = "Raporto Diskriminimin";
  const translateMe = (key, langId) => {
    let diqka = propss?.langData?.filter((el) => el.key == key)[0];
    if (langId === "al") {
      return diqka?.content_al;
    }
    if (langId === "sr") {
      return diqka?.content_sr;
    }
    if (langId === "en") {
      return diqka?.content_en;
    }
  };

  useEffect(() => {
    // console.log("dionn");
    let isMounted = true;
    axios
      .get("/general/news/all")
      .then((res) => {
        // console.log("dataaa", res?.data);
        if (isMounted) {
          setnews(res?.data.slice(0, 3));
        }
      })
      .catch((err) => {
        console.log(err);
      });
    return () => {
      isMounted = false;
    };
  }, []);

  return (
    <>
      <section className="news container my-5">
          <div>
          <h2 className="text-md-start text-center text-uppercase text-dark">
            {translateMe("newsTitle", propss?.landId)}
          </h2>
          <div className="row align-items-center reverse-row">
            <div className="col-lg-10 col-md-9 col-12 py-1">
              <div className="text-dark align-bottom text-md-start text-center">
              {translateMe("learnMoreAboutNews", propss?.landId)}
              </div>
            </div>
            <div className="col-lg-2 col-md-3 col-12 text-md-end text-center py-1">
              <Link className="btn main-button" to={{ pathname: "/news" }}>{translateMe("showMore", propss?.landId)}</Link>

            </div>
          </div>
            <div className="row justify-content-center my-3">
              {news?.map((el) => {
                let backgroundImage = `${process.env.REACT_APP_UPLOADS}${el.pic_url}`;
                if (propss?.landId == "al") {
                  return (
                    <div className="col-lg-4 col-md-6 col-12 mb-4" key={el.id}>
                      <div
                        className="news-card shadow"
                        style={{
                          backgroundImage: `url(${backgroundImage})`,
                          backgroundSize: "cover",
                          backgroundPosition: "center",
                        }}
                      >
                        {/* Overlay Div */}
                        <div className="overlay"></div>

                        <Link to={{ pathname: "/news/" + el.id }}>
                        <span className="newsSubtitle"> {translateMe("newsSubtitle", propss?.landId)}</span>
                          <h5 className="card-title mt-1">
                            {el.name_al.slice(0, 40) + "..."}
                          </h5>
                          <div className="card-body text-single px-0">
                            <p
                              className="card-text "
                              dangerouslySetInnerHTML={{
                                __html: el.desc_al.slice(0, 180) + "...",
                              }}
                            ></p>
                          </div>
                          <div className="row justify-content-end">
                            <div className="arrowbg text-center">
                              <i
                                className="fa fa-arrow-right text-dark py-2"
                                aria-hidden="true"
                              ></i>
                            </div>
                          </div>
                        </Link>
                      </div>
                    </div>
                  );
                } else if (propss?.landId == "en") {
                  return (
                    <div className="col-lg-4 col-md-6 col-12 mb-4" key={el.id}>
                      <div
                        className="news-card shadow"
                        style={{
                          backgroundImage: `url(${backgroundImage})`,
                          backgroundSize: "cover",
                          backgroundPosition: "center",
                        }}
                      >
                        {/* Overlay Div */}
                        <div className="overlay"></div>

                        <span className="newsSubtitle"> {translateMe("newsSubtitle", propss?.landId)}</span>
                        <Link to={{ pathname: "/news/" + el.id }}>
                          <h5 className="card-title mt-2">
                            {el.name_en.slice(0, 40) + "..."}
                          </h5>
                          <div className="card-body text-single px-0">
                            <p
                              className="card-text "
                              dangerouslySetInnerHTML={{
                                __html: el.desc_en.slice(0, 180) + "...",
                              }}
                            ></p>
                          </div>
                          <div className="row justify-content-end">
                            <div className="arrowbg text-center">
                              <i
                                className="fa fa-arrow-right text-dark py-2"
                                aria-hidden="true"
                              ></i>
                            </div>
                          </div>
                        </Link>
                      </div>
                    </div>
                  );
                } else if (propss?.landId == "sr") {
                  return (
                    <div className="col-lg-4 col-md-6 col-12 mb-4" key={el.id}>
                      <div
                        className="news-card shadow"
                        style={{
                          backgroundImage: `url(${backgroundImage})`,
                          backgroundSize: "cover",
                          backgroundPosition: "center",
                        }}
                      >
                        {/* Overlay Div */}
                        <div className="overlay"></div>

                        <span className="newsSubtitle"> {translateMe("newsSubtitle", propss?.landId)}</span>
                         <Link to={{ pathname: "/news/" + el.id }}>
                          <h5 className="card-title mt-2">
                            {el.name_sr.slice(0, 40) + "..."}
                          </h5>
                          <div className="card-body text-single px-0">
                            <p
                              className="card-text "
                              dangerouslySetInnerHTML={{
                                __html: el.desc_sr.slice(0, 180) + "...",
                              }}
                            ></p>
                          </div>
                          <div className="row justify-content-end">
                            <div className="arrowbg text-center">
                              <i
                                className="fa fa-arrow-right text-dark py-2"
                                aria-hidden="true"
                              ></i>
                            </div>
                          </div>
                        </Link>
                      </div>
                    </div>
                  );
                }
              })}
            </div>
          </div>
      </section>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    // loggedIn: state.data.loggedIn,
    // reportData: state.data.reportData,
    landId: state.data.langId,
    langData: state.data.langData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    // logMeIn: () => dispatch(logMeIn()),
    // setReportData: (data) => dispatch(setReportData(data)),
    // Clear: () => dispatch(Clear()),
    // setLangID: (id) => dispatch(setLangID(id)),
    // setLangData: (data) => dispatch(setLangData(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(News);
