import React from "react";
import "./Footer.scss";
import Logo from "../images/Group 80.png";
import app from "./images/google.png";
import android from "./images/app.png";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

const Footer = (props) => {
  const translateMe = (key, langId) => {
    let diqka = props?.langData?.filter((el) => el.key === key)[0];
    if (langId === "al") {
      return diqka?.content_al;
    }
    if (langId === "sr") {
      return diqka?.content_sr;
    }
    if (langId === "en") {
      return diqka?.content_en;
    }
  };

  return (
    <>
      <footer className="bg-warning py-5">
        <div className="container text-center text-md-start">
          <div className="row align-items-center">
            {/* Logo Section */}
            <div className="col-md-4 mb-4">
              <img src={Logo} alt="Logo" className="img-fluid logo" />
            </div>

            {/* Address Section */}
            <div className="col-md-4 mb-4 d-md-flex align-items-start flex-column">
              <h6 className="fw-bold mb-3">Adresa</h6>
              <p>
                <div className="pt-1">Ndertesa e Qeverise,</div>
                <div className="pt-1">
                  Zyra per Qeverisje te Mire / Kryeministri
                </div>
                <div className="pt-1">Kati i 6-të, Zyra Nr. 602-607</div>
                <div className="pt-1">Sheshi "Nene Tereza" p.n. 10000</div>
                <div className="pt-1">Prishtinë - Kosovë</div>
              </p>
            </div>

            {/* Links Section */}
            <div className="col-md-2 mb-4 d-md-flex align-items-start flex-column">
              <h6 className="fw-bold mb-3">Faqet</h6>
              <ul className="list-unstyled">
                <li className="mt-1">
                  <Link to="/Terms" className="text-dark">
                    <u>{translateMe("terms", props?.landId)}</u>
                  </Link>
                </li>
                <li className="mt-1">
                  <Link to="/Privacy" className="text-dark">
                    <u>{translateMe("PrivacyPolicy", props?.landId)}</u>
                  </Link>
                </li>
                <li className="mt-1">
                  <Link to="/aboutus" className="text-dark">
                    <u>{translateMe("aboutUs", props?.landId)}</u>
                  </Link>
                </li>
                <li className="mt-1">
                  <Link to="/Mision" className="text-dark">
                    <u>{translateMe("ourVision", props?.landId)}</u>
                  </Link>
                </li>
                <li className="mt-1">
                  <Link to="/" className="text-dark">
                    <u>{translateMe("ourPartners", props?.landId)}</u>
                  </Link>
                </li>
              </ul>
            </div>

            {/* Download App Section */}
            <div className="col-md-2 mb-4 img-download">
              <h6 className="fw-bold">Shkarko aplikacionin</h6>
              <div className="row">
                <div className="col-6">
                  <a
                    href="https://play.google.com/store/apps/details?id=com.harrisia.antidiscrimination"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={app}
                      alt="Google Play"
                      className="img-fluid image"
                    />
                  </a>
                </div>
                <div className="col-6">
                  <a
                    href="https://apps.apple.com/app/raporto-diskriminimin/id1610633355"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={android}
                      alt="App Store"
                      className="img-fluid image"
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    landId: state.data.langId,
    langData: state.data.langData,
  };
};

export default connect(mapStateToProps)(Footer);
