import react, { useState } from "react";
import { Link } from "react-router-dom";
import MenuItems from "./data/menuitems";
import { connect } from "react-redux";
import { setLangID, setLangData } from "../../redux/Functions/actions";
import Logo from "./images/Logoshqip.png";
const Mobile = (props) => {
  const [isOpen, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(!isOpen);
  };
  const translateMe = (key, langId) => {
    let diqka = props?.langData?.filter((el) => el.key == key)[0];
    if (langId === "al") {
      return diqka?.content_al;
    }
    if (langId === "sr") {
      return diqka?.content_sr;
    }
    if (langId === "en") {
      return diqka?.content_en;
    }
  };
  return (
    <>
      <div className="mobileMenu">
        <div className="row mx-2  align-items-center">
          <div className="col-9 ">     
           
                
          <div className="logo">
                  <Link to="/">
                    <img src={Logo} alt="logo" className="img-fluid"/>
                  </Link>
                </div> 
              </div>
          <div className="col-3 text-end">
            <button onClick={handleClick}>
              {isOpen ? (
                <i className="fa fa-times" />
              ) : (
                <i className="fa fa-bars" />
              )}
            </button>
          </div>
        </div>
      </div>
      {!!isOpen && (
        <div className="mobileMenuContent">
          <ul  className="px-0">
            <li className="">
              <Link
                to="/"
                className="text-dark text-decoration-none"
                onClick={handleClick}
              >
                {translateMe("mainMenuHome", props?.landId)}
              </Link>
              <Link
                to="/aboutus"
                className="text-dark text-decoration-none"
                onClick={handleClick}
              >
                {translateMe("mainMenuAboutUs", props?.landId)}
              </Link>
              {/* <Link
                to="/events"
                className="text-dark text-decoration-none"
                onClick={handleClick}
              >
                Events{" "}
              </Link> */}
              <Link
                to="/news"
                className="text-dark text-decoration-none"
                onClick={handleClick}
              >
                {translateMe("mainMenuNews", props?.landId)}
              </Link>
              <div className="dropdon position-relative d-table ">
                <button
                  className="btn ps-2  btn-light dropdown-toggle py-1 ps-0"
                  type="button"
                  id="dropdownMenuButton1"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  style={{
                    backgroundColor: "transparent",
                    border: "none",
                    paddingLeft: "4px",
                    fontWeight: 400,
                  }}
                >
                  {translateMe("mainMenuLaws", props?.landId)}
                </button>
                <ul
                  className="dropdown-menu drop_background"
                  aria-labelledby="dropdownMenuButton1"
                >
                  <li className="pb-0">
                    {" "}
                    <Link
                      to="/LawsNationality"
                      className="text-dark text-decoration-none"
                      onClick={handleClick}
                    >
                      {translateMe("mainMenuLawsOpt1", props?.landId)}
                    </Link>
                  </li>
                  <li className="pb-0">
                    {" "}
                    <Link
                      to="/LawsInternacionality"
                      className="text-dark text-decoration-none "
                      onClick={handleClick}
                    >
                      {translateMe("mainMenuLawsOpt2", props?.landId)}
                    </Link>
                  </li>
                  <li className="pb-0">
                    {" "}
                    <Link
                      to="/Constitution"
                      className=" text-dark text-decoration-none"
                      onClick={handleClick}
                    >
                      {translateMe("mainMenuLawsOpt3", props?.landId)}
                    </Link>
                  </li>
                </ul>
              </div>
              <Link
                to="/mision"
                className="text-dark text-decoration-none"
                onClick={handleClick}
              >
                {translateMe("mainMenuMission", props?.landId)}
              </Link>

              <Link
                to="/DiversityAndEquality"
                className="text-dark text-decoration-none"
                onClick={handleClick}
              >
                {translateMe("topMenuDiversity", props?.landId)}
              </Link>

              <Link
                to="/protection"
                className="text-dark text-decoration-none"
                onClick={handleClick}
              >
                {translateMe("mainMenuProtection", props?.landId)}
              </Link>
        
            </li>
          </ul>
        </div>
      )}
    </>
  );
};
// export default Mobile;

const mapStateToProps = (state) => {
  return {
    // loggedIn: state.data.loggedIn,
    reportData: state.data.reportData,
    landId: state.data.langId,
    langData: state.data.langData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    // logMeIn: () => dispatch(logMeIn()),
    // setReportData: (data) => dispatch(setReportData(data)),
    // Clear: () => dispatch(Clear()),
    setLangID: (id) => dispatch(setLangID(id)),
    setLangData: (data) => dispatch(setLangData(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Mobile);

