import React from "react";
import "./Slider.scss";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import SliderImage1 from "./images/SliderImage.jpg";
import SliderImage2 from "./images/SliderImage2 (2).jpg";
import SliderImage3 from "./images/SliderImage3.jpg";
import SliderImage4 from "./images/SliderImage4.jpg";
import SliderImageNew41 from "./images/fotoja1.jpg";
import Slider_logo from "./images/antig-icon.svg";
import bgImage from "./images/bg1.png";
import app from "./images/app-store-badge.png";
import android from "./images/google-play-badge.png";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";
import { Slide } from "react-toastify";
import { Link } from "react-router-dom";
// const  Slider (props)  = (props) => {
const Slider = (props) => {
  const translateMe = (key, langId) => {
    let diqka = props?.langData?.filter((el) => el.key == key)[0];
    if (langId === "al") {
      return diqka?.content_al;
    }
    if (langId === "sr") {
      return diqka?.content_sr;
    }
    if (langId === "en") {
      return diqka?.content_en;
    }
  };
  const location = useLocation();
  const { pathname } = location;
  const splitLocation = pathname.split("/");
  return (
    <>
    <Carousel
      showArrows={false}
      showThumbs={false}
      interval={2000}
      emulateTouch
      // autoPlay
      autoFocus
      stopOnHover
      infiniteLoop
      className=" carousel-part"
    >
      <div>
        <img src={SliderImage1} className="slider-image float-end" />
        <div className="svg">
          <img src={bgImage} className=" position-absolute  start-0 w-auto" />
        </div>

        <div className="container text-start logo">
          <img src={Slider_logo} className="slider-logo mb-3" />
          <p className={"slider-text text-dark mt-3"}>
            {translateMe("sliderText", props?.landId)}
            <div className="Logo_app_download ">
              <a
                href="https://apps.apple.com/app/raporto-diskriminimin/id1610633355"
                target="_blank"
              >
                <img src={app} alt="" />
              </a>
              <a
                href="https://play.google.com/store/apps/details?id=com.harrisia.antidiscrimination"
                target="_blank"
              >
                <img src={android} alt="" />
              </a>
            </div>
          </p>
        </div>
      </div>
      <div>
        <img src={SliderImage2} className="slider-image float-end" />
        <div className="svg">
          <img src={bgImage} className=" position-absolute  start-0 w-auto" />
        </div>
        <div className="container text-start">
          <img src={Slider_logo} className="slider-logo mb-3" />

          <p className={"slider-text text-dark mt-3"}>
            {translateMe("sliderText", props?.landId)}
            <div className="Logo_app_download">
              <a
                href="https://apps.apple.com/app/raporto-diskriminimin/id1610633355"
                target="_blank"
              >
                <img src={app} alt="" />
              </a>
              <a
                href="https://play.google.com/store/apps/details?id=com.harrisia.antidiscrimination"
                target="_blank"
              >
                <img src={android} alt="" />
              </a>
            </div>
          </p>
        </div>
      </div>
      <div>
        <img src={SliderImage3} className="slider-image float-end" />
        <div className="svg">
          <img src={bgImage} className=" position-absolute  start-0 w-auto" />
        </div>
        <div className="container text-start">
          <img src={Slider_logo} className="slider-logo mb-3" />

          <p className={"slider-text text-dark mt-3"}>
            {translateMe("sliderText", props?.landId)}
            <div className="Logo_app_download">
              <a
                href="https://apps.apple.com/app/raporto-diskriminimin/id1610633355"
                target="_blank"
              >
                <img src={app} alt="" />
              </a>
              <a
                href="https://play.google.com/store/apps/details?id=com.harrisia.antidiscrimination"
                target="_blank"
              >
                <img src={android} alt="" />
              </a>
            </div>
          </p>
        </div>
      </div>
      <div>
        <img src={SliderImageNew41} className="slider-image float-end" />
        <div className="svg">
          <img src={bgImage} className=" position-absolute  start-0 w-auto" />
        </div>
        <div className="container text-start">
          <img src={Slider_logo} className="slider-logo mb-3" />
          <p className={"slider-text text-dark mt-3"}>
            {translateMe("sliderText", props?.landId)}
            <div className="Logo_app_download">
              <a
                href="https://apps.apple.com/app/raporto-diskriminimin/id1610633355"
                target="_blank"
              >
                <img src={app} alt="" />
              </a>
              <a
                href="https://play.google.com/store/apps/details?id=com.harrisia.antidiscrimination"
                target="_blank"
              >
                <img src={android} alt="" />
              </a>
            </div>
          </p>
        </div>
      </div>
    </Carousel>
    <div className="container">
        <div className="row justify-content-center manual-report-main">
          <div className="manual-report-section col-lg-8 col-md-9 px-0">
            <div className="manual half left-half">
              <Link to="/manuals" className="manual-button">
                <div className="content">
                  <i className="fa fa-file-text-o" aria-hidden="true"></i>
                  <div className="text-content">
                    <h4>MANUALET</h4>
                    <h6 className="fw-lighter">E PUBLIKUARA</h6>
                  </div>
                </div>
              </Link>
            </div>
            <div className="report half right-half">
              <Link to="/report" className="report-button">
                <div className="content">
                  <div className="text-content">
                    <h4>RAPORTO</h4>
                    <h6 className="fw-lighter">NË PLATFORMË</h6>
                  </div>
                  <i
                    className="fa fa-arrow-right fw-lighter"
                    aria-hidden="true"
                  ></i>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    // loggedIn: state.data.loggedIn,
    // reportData: state.data.reportData,
    landId: state.data.langId,
    langData: state.data.langData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    // logMeIn: () => dispatch(logMeIn()),
    // setReportData: (data) => dispatch(setReportData(data)),
    // Clear: () => dispatch(Clear()),
    // setLangID: (id) => dispatch(setLangID(id)),
    // setLangData: (data) => dispatch(setLangData(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Slider);
