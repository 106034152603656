import React, { useState } from "react";
import { connect } from "react-redux";
import myVideo from "./howToUse.mov";
import placeholderImage from "./../Slider/images/fotoja1.jpg";
import "./howToUse.scss";

const HowToUse = (props) => {
  const [isPlaying, setIsPlaying] = useState(false);

  const translateMe = (key, langId) => {
    let diqka = props?.langData?.filter((el) => el.key == key)[0];
    if (langId === "al") {
      return diqka?.content_al;
    }
    if (langId === "sr") {
      return diqka?.content_sr;
    }
    if (langId === "en") {
      return diqka?.content_en;
    }
  };

  const playVideo = () => {
    setIsPlaying(true);
    document.getElementById("howToUseVideo").play();
  };

  return (
    <>
      <div className="container mt-5">
        <div className="howToUse">
          <div className="row align-items-center px-md-5 p-3">
            <div className="col-12 col-md-8 text-center">
              <h2 className="text-uppercase pt-3">
                {translateMe("howToUse", props?.landId)}{" "}
              </h2>
              <div className="row justify-content-center">
                <div className="col-md-8 col-11">
                  <h5 className="py-4">
                  {translateMe("learnHowToUse", props?.landId)}{" "}
                  </h5>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-4 video-container">
              {!isPlaying && (
                <div className="video-overlay" onClick={playVideo}>
                  <img
                    src={placeholderImage}
                    alt="Video Placeholder"
                    className="video-placeholder"
                  />
                  <button className="play-button">
                    <i className="fa fa-play-circle"></i>
                  </button>
                </div>
              )}
              <video
                id="howToUseVideo"
                controls
                preload="none"
                muted
                loop
                className={`video ${isPlaying ? "active" : ""}`}
              >
                <source src={myVideo} type="video/mp4" />
              </video>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    landId: state.data.langId,
    langData: state.data.langData,
  };
};

export default connect(mapStateToProps)(HowToUse);
