import React, { useState, useEffect } from "react";
import Loader from "../loader/loader";
import axios from "../../axios";
import { connect } from "react-redux";
import "./partner.scss";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Link } from "react-social-sharing/dist-modules/common";

const responsiveR = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 3,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 769 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 769, min: 0 },
    items: 1,
  },
};

const Partneret = (propss) => {
  const [iSloading, setLoading] = useState(true);
  const [partnerIMG, setPartnerIMG] = useState(null);

  const translateMe = (key, langId) => {
    const translation = propss?.langData?.find((el) => el.key === key);
    switch (langId) {
      case "al":
        return translation?.content_al;
      case "sr":
        return translation?.content_sr;
      case "en":
        return translation?.content_en;
      default:
        return "";
    }
  };

  useEffect(() => {
    let isMounted = true; // Track if component is mounted
    axios
      .get("/general/partners")
      .then((res) => {
        if (isMounted) {
          setPartnerIMG(res.data);
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log("error", err);
        if (isMounted) setLoading(false); // Set loading to false even if there's an error
      });

    return () => {
      isMounted = false; // Cleanup: Prevent updates if the component is unmounted
    };
  }, []);

  if (iSloading) {
    return <Loader />;
  } else if (!partnerIMG || partnerIMG.length === 0) {
    return <div>No partners available.</div>;
  }

  return (
    <>
      <div className="container partneret my-5">
        <h2 className="text-start text-uppercase text-dark">
          {translateMe("ourPartners", propss?.landId)}
        </h2>
        <div className="row  justify-content-end">
          <div className="col-12">
            <Carousel
              responsive={responsiveR}
              infinite={true}
              autoPlay={true}
              autoPlaySpeed={3000}
              arrows={true}
            >
              {partnerIMG?.map((item) => (
                <div
                  className="trustedby-item col-lg-3 col-md-4 col-sm-6 col-12"
                  key={item.id}
                >
                  <div className="img-container">
                    <img
                      src={`${process.env.REACT_APP_UPLOADS}` + item.pic_url}
                      alt={item?.name}
                    />
                  </div>
                </div>
              ))}
            </Carousel>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  landId: state.data.langId,
  langData: state.data.langData,
});

export default connect(mapStateToProps)(Partneret);
