import React, { useEffect, useState } from "react";
import "./privacy.scss";
import axios from "../../axios";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";
import Loader from "../loader/loader";
import {
  FacebookIcon,
  TwitterIcon,
  FacebookShareButton,
  TwitterShareButton,
  LinkedinIcon,
  LinkedinShareButton,
} from "react-share";

const Privacy = (props) => {
  const [privacyPolicies, setPrivacyPolicies] = useState([]);
  const [isLoading, setLoading] = useState(true);

  const translateMe = (key, langId) => {
    const translation = props?.langData?.find((el) => el.key === key);
    switch (langId) {
      case "al":
        return translation?.content_al;
      case "sr":
        return translation?.content_sr;
      case "en":
        return translation?.content_en;
      default:
        return "";
    }
  };

  useEffect(() => {
    axios
      .get("/general/privacy_policy")
      .then((res) => {
        setPrivacyPolicies(res.data);
        setLoading(false);
      })
      .catch((err) => console.log("error", err));
  }, []);

  const location = useLocation();
  const { pathname } = location;
  const shareUrl = `https://raportodiskriminimin.org${pathname}`;

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <div className="container">
          <div className="privacy-policy row justify-content-center">
            <div className="col-md-10 col-12">
              <h1 className="text-center">{translateMe("PrivacyPolicy", props?.landId)}</h1>
              {privacyPolicies?.map((e) => (
                <div className="privacy-part" key={e.id}>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: e[`content_${props?.landId}`],
                    }}
                  ></p>
                </div>
              ))}
            </div>

            <div className="allIcons">
              <div className="smallIcons">
                <FacebookShareButton url={shareUrl}>
                  <FacebookIcon size={40} round />
                </FacebookShareButton>
              </div>
              <div className="smallIcons">
                <TwitterShareButton url={shareUrl}>
                  <TwitterIcon size={40} round />
                </TwitterShareButton>
              </div>
              <div className="smallIcons">
                <LinkedinShareButton url={shareUrl}>
                  <LinkedinIcon size={40} round />
                </LinkedinShareButton>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  landId: state.data.langId,
  langData: state.data.langData,
});

export default connect(mapStateToProps)(Privacy);
