import React, { useState, useEffect } from "react";
import axios from "../../axios";
import { Link } from "react-router-dom";
import "./Laws.scss";
import { connect } from "react-redux";

const Laws = (propss) => {
  const [laws, setlaws] = useState([]);

  const translateMe = (key, langId) => {
    let diqka = propss?.langData?.filter((el) => el.key == key)[0];
    if (langId === "al") {
      return diqka?.content_al;
    }
    if (langId === "sr") {
      return diqka?.content_sr;
    }
    if (langId === "en") {
      return diqka?.content_en;
    }
  };

  useEffect(() => {
    let isMounted = true;

    axios
      .get("/general/laws/all")
      .then((res) => {
        if (isMounted) {
          setlaws(res?.data?.slice(0, 3));
        }
      })
      .catch((err) => {
        console.log(err);
      });

    return () => {
      isMounted = false;
    };
  }, []);

  // console.log("xhevdet", laws);
  return (
    <>
      <section className="laws new-bg-color container my-5">
        <div>
          <h2 className="text-md-start text-center text-uppercase text-dark">
            {translateMe("lawsTitle", propss?.landId)}
          </h2>
          <div className="row align-items-center reverse-row">
            <div className="col-lg-10 col-md-9 col-12 py-1">
              <div className="text-dark align-bottom text-md-start text-center">
                {translateMe("learnMoreAboutLaws", propss?.landId)}
              </div>
            </div>
            <div className="col-lg-2 col-md-3 col-12 text-md-end text-center py-1">
              <Link
                className="btn main-button"
                to={{ pathname: "/LawsNationality/" }}
              >
                {translateMe("showMore", propss?.landId)}
              </Link>
            </div>
          </div>
          <div className="row justify-content-center my-4">
            {laws?.map((el) => {
              const title =
                propss?.landId === "al"
                  ? el.name_al
                  : propss?.landId === "en"
                  ? el.name_en
                  : el.name_sr;
              const description =
                propss?.landId === "al"
                  ? el.desc_al
                  : propss?.landId === "en"
                  ? el.desc_en
                  : el.desc_sr;

              return (
                <div className="col-lg-4 col-md-6 col-12 mb-4" key={el.id}>
                  <div className="p-4 shadow">
                    <span className="text-secondary">Ligji</span>
                    <Link to={{ pathname: "/LawsInternacionality/" }}>
                      <h5
                        className="Card-title mt-2"
                        dangerouslySetInnerHTML={{ __html: title?.slice(0, 30) }}
                      ></h5>
                      <div className="card-body text-single">
                        <p
                          dangerouslySetInnerHTML={{
                            __html: description?.slice(0, 120) + "...",
                          }}
                        ></p>
                      </div>
                      <div className="row justify-content-end">
                        <div className="arrowbg text-center py-2">
                          <i
                            className="fa fa-arrow-right text-light"
                            aria-hidden="true"
                          ></i>
                        </div>
                      </div>
                    </Link>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    // loggedIn: state.data.loggedIn,
    // reportData: state.data.reportData,
    landId: state.data.langId,
    langData: state.data.langData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    // logMeIn: () => dispatch(logMeIn()),
    // setReportData: (data) => dispatch(setReportData(data)),
    // Clear: () => dispatch(Clear()),
    // setLangID: (id) => dispatch(setLangID(id)),
    // setLangData: (data) => dispatch(setLangData(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Laws);
