import React, { useState, useEffect } from "react";
import axios from "../../axios";
import "./Singlenews.scss";
import Loader from "../loader/loader";
import { useParams } from "react-router";
import { connect } from "react-redux";
import {
  FacebookIcon,
  TwitterIcon,
  FacebookShareButton,
  TwitterShareButton,
  LinkedinIcon,
  LinkedinShareButton,
} from "react-share";
import { Helmet } from "react-helmet";

const Singlenews = (props) => {
  const [data, setData] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [singleNews, setSingleNews] = useState();
  const { id } = useParams();

  const shareUrl = `https://raportodiskriminimin.org/news/${id}`;

  const translateMe = (key, langId) => {
    let diqka = props?.langData?.find((el) => el.key === key);
    if (langId === "al") {
      return diqka?.content_al;
    }
    if (langId === "sr") {
      return diqka?.content_sr;
    }
    if (langId === "en") {
      return diqka?.content_en;
    }
  };

  const getData = async () => {
    try {
      const response = await axios.get("/general/news/all");
      setData(response.data);
      setLoading(false);
    } catch (err) {
      console.log("error", err);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    const selectedNews = data.find((el) => el.id === parseInt(id));
    setSingleNews(selectedNews);
  }, [data, id]);

  if (isLoading) {
    return <Loader />;
  }

  const Data = singleNews;

  if (!Data) {
    return <div>No news found</div>;
  }
  const videoUrl = "https://clashdome.io/images/newHome/video_home.mp4";
  const content = (langId) => (
    <>
      <Helmet>
        <title>{Data[`name_${langId}`]}</title>
        <meta name="description" content={Data[`desc_${langId}`]} />
        <meta
          name="keywords"
          content="diskriminim, raportim, platformë, anonim, sigurt, barazi"
        />
        <meta property="og:title" content={Data[`name_${langId}`]} />
        <meta property="og:description" content={Data[`name_${langId}`]} />
        <meta
          property="og:image"
          content={`${process.env.REACT_APP_UPLOADS}${Data.pic_url}`}
        />
        <meta property="og:url" content={shareUrl} />
      </Helmet>

      <div className="container">
        <div className="row mt-5 mb-5">
          <div className="col-md-5 col-10 ">
            <div className="border-img">
              {Data?.pic_url ? (
                <img
                  src={`${process.env.REACT_APP_UPLOADS}${Data?.pic_url}`}
                  className="card-img-top"
                  alt="..."
                />
              ) : (
                <img src="https://image.freepik.com/free-vector/activist-fists-with-different-skin-color-end-racism-text-abstract-anti-racist-strike-other-protest-label-emblem-card-template-blue-background_167715-714.jpg" />
              )}
            </div>
            {Data?.video_url && (
              <video controls preload muted loop className="mt-3">
                <source
                  src={`${process.env.REACT_APP_UPLOADS}${Data?.video_url}`}
                  type="video/mp4"
                ></source>
              </video>
            )}
          </div>
          <div className="col-md-7">
            <h5 className="fw-bold">{Data[`name_${langId}`]}</h5>
            <div
              className=""
              dangerouslySetInnerHTML={{ __html: Data[`desc_${langId}`] }}
            ></div>
            <div className="allIcons">
              <div className="smallIcons">
                <FacebookShareButton
                  url={shareUrl}
                  hashtag={Data[`name_${langId}`]}
                  title={String(Data[`name_${langId}`])}
                >
                  <FacebookIcon size={40} round />
                </FacebookShareButton>
              </div>
              <div className="smallIcons">
                <TwitterShareButton
                  url={shareUrl}
                  title={Data[`name_${langId}`]}
                >
                  <TwitterIcon size={40} round />
                </TwitterShareButton>
              </div>
              <div className="smallIcons">
                <LinkedinShareButton
                  url={shareUrl}
                  title={String(Data[`name_${langId}`])}
                >
                  <LinkedinIcon size={40} round />
                </LinkedinShareButton>
              </div>
            </div>
          </div>
        
        </div>
      </div>
    </>
  );

  return (
    <>
      {props.landId === "al" && content("al")}
      {props.landId === "en" && content("en")}
      {props.landId === "sr" && content("sr")}
    </>
  );
};

const mapStateToProps = (state) => ({
  landId: state.data.langId,
  langData: state.data.langData,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Singlenews);
