import React, { useEffect, useState } from "react";
import './Links.scss';

const Links = () => {
    const [selectedCase, setSelectedCase] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setSelectedCase(Math.floor(Math.random() * reportCase.length));
        }, 1500);

        return () => clearInterval(interval);
    }, [selectedCase]);

    const reportCase = [
        'Roma',
        'Minorities',
        'Awareness',
        'Report',
        'Egyptian',
        'National',
        'Laws',
        'Protection',
        'Anti-Gypsism',
        'Ashkali',
        'Prevention',
        'Human Rights',
        'Kosovo',
        'Discrimination',
    ];

    return (
        <div className="container my-5">
            <a href="/report">
                <section className="foto-links">
                    <div className="links">
                        {reportCase.map((word, index) => (
                            <div key={index}>
                                <p className={`link ${word} ${selectedCase === index ? "active" : ""}`}>
                                    {word}
                                </p>
                            </div>
                        ))}
                    </div>
                </section>
            </a>
        </div>
    );
};

export default Links;
