import React, { useState, useEffect } from "react";
import ReactPaginate from "react-paginate";
import "./Morenews.scss";
import "../../App.css";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import axios from "../../axios";
import Loader from "../loader/loader";

const Morenews = (propss) => {
  const [news, setNews] = useState([]);
  const [iSloading, setLoading] = useState(true);
  const [pageNumber, setPageNumber] = useState(0);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const casesPerPage = 6;
  const pagesVisited = pageNumber * casesPerPage;

  const translateMe = (key, langId) => {
    const item = propss?.langData?.find((el) => el.key === key);
    if (langId === "al") {
      return item?.content_al;
    }
    if (langId === "sr") {
      return item?.content_sr;
    }
    if (langId === "en") {
      return item?.content_en;
    }
  };

  useEffect(() => {
    axios
      .get("/general/news/all")
      .then((res) => {
        setNews(res?.data);
        setLoading(false);
      })
      .catch((err) => console.log(err));

    // Update window width on resize
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  if (iSloading) {
    return <Loader />;
  }

  const displayCases = news
    .slice(pagesVisited, pagesVisited + casesPerPage)
    .map((el) => {
      const language = propss.landId;
      let name, desc;

      if (language === "al") {
        name = el.name_al;
        desc = el.desc_al;
      } else if (language === "en") {
        name = el.name_en;
        desc = el.desc_en;
      } else if (language === "sr") {
        name = el.name_sr;
        desc = el.desc_sr;
      }

      const backgroundImage = el.pic_url
        ? `${process.env.REACT_APP_UPLOADS}${el.pic_url}`
        : "https://image.freepik.com/free-vector/activist-fists-with-different-skin-color-end-racism-text-abstract-anti-racist-strike-other-protest-label-emblem-card-template-blue-background_167715-714.jpg";

      return (
        <div className="col-lg-4 col-md-6 col-12 my-4" key={el.id}>
          <div
            className="more-news-card shadow p-3"
            style={{
              backgroundImage: `url(${backgroundImage})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          >
            <div className="overlay"></div>
            <span className="text-secondary">{translateMe("Ligji", language)}</span>
            <Link to={{ pathname: "/news/" + el.id }}>
              <h5 className="card-title mt-2 text-white">
                {name.slice(0, 80) + "..."}
              </h5>
              <div className="card-body text-single px-0">
                <p
                  className="card-text text-white"
                  dangerouslySetInnerHTML={{
                    __html: desc.slice(0, 180) + "...",
                  }}
                ></p>
              </div>
            </Link>
          </div>
        </div>
      );
    });

  const pageCount = Math.ceil(news.length / casesPerPage);

  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };

  // Set the number of page ranges displayed based on window width
  const pageRangeDisplayed = windowWidth <= 768 ? 1 : 3;
  const marginPagesDisplayed = windowWidth <= 768 ? 1 : 2;

  return (
    <div>
      <div className="container pt-5">
        <div className="row">{displayCases}</div>
        <ReactPaginate
          previousLabel={"Previous"}
          nextLabel={"Next"}
          pageCount={pageCount}
          onPageChange={changePage}
          containerClassName={"paginationBttns"}
          previousLinkClassName={"previousBttn"}
          nextLinkClassName={"nextBttn"}
          disabledClassName={"paginationDisabled"}
          activeClassName={"paginationActive"}
          pageRangeDisplayed={pageRangeDisplayed} // Fewer page numbers for smaller screens
          marginPagesDisplayed={marginPagesDisplayed} // Show first and last few pages with ellipses
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  landId: state.data.langId,
  langData: state.data.langData,
});

export default connect(mapStateToProps)(Morenews);
