import React from "react";
import "./Home.scss";
import About from "../Aboutus/Aboutus";
import News from "../News/News";
import Events from "../Events/Events";
import Slider from "../Slider/Slider";
import Laws from "../Laws/Laws";
import Links from "../Links/Links";
import Parteret from "../partneret/partner";
import HowToUse from "../howToUse/howToUse";
import CustomSlider from "../Slider/SliderNewDesign";

const Home = () => {
  return (
    <>
      {/* <CustomSlider/> */}
      <Slider />
      <About />
      <HowToUse />
      <News />
      <Laws />
      <Parteret />
      <Links />
      {/* <Events /> */}
    </>
  );
};

export default Home;
