import React, { useEffect, useState } from "react";
import "./Constitution.scss";
import Loader from "../loader/loader";
import Pdf_shqip from "./pdf/Strategjia_shqip.pdf";
import Pdf_eng from "./pdf/Strategy_eng.pdf";
import Pdf_srb from "./pdf/Strategija_serbe.pdf";
import Pdf_rome from "./pdf/Startegia_rome.pdf";

const Constitution = () => {
  const [iSloading, setLoading] = useState(true);
  
  useEffect(() => {
    setTimeout(() => setLoading(false), 200);
  }, []);

  return (
    <>
      {iSloading ? (
        <Loader />
      ) : (
        <div className="container constitution">
          <div className="row">
            <h1 className="mb-5 mt-5 text-center">
              Kushtetuta
            </h1>

            <div className="col-lg-4 col-md-6 col-12 mb-4">
              <a href="https://gzk.rks-gov.net/ActDetail.aspx?ActID=3702" target="_blank" rel="noopener noreferrer" className="card-link">
                <div className="shadow p-4">
                  <h5 className="card-title">Ligji Shqip</h5>
                  <div className="card-body text-single">
                    <p>
                      <span>Lloji i aktit: Kushtetuta</span><br />
                      <span>Numri i aktit: K-09042008</span><br />
                      <span>Institucioni: Kuvendi i Republikës së Kosovës</span>
                    </p>
                  </div>
                  <div className="row justify-content-end">
                    <div className="arrowbg text-center py-2">
                      <i className="fa fa-arrow-right text-light" aria-hidden="true"></i>
                    </div>
                  </div>
                </div>
              </a>
            </div>

            <div className="col-lg-4 col-md-6 col-12 mb-4">
              <a href="https://gzk.rks-gov.net/ActDetail.aspx?ActID=3702" target="_blank" rel="noopener noreferrer" className="card-link">
                <div className="shadow p-4">
                  <h5 className="card-title">Laws English</h5>
                  <div className="card-body text-single">
                    <p>
                      <span>Type of act: Constitution</span><br />
                      <span>Act number: K-09042008</span><br />
                      <span>Institution: Assembly of the Republic of Kosovo</span>
                    </p>
                  </div>
                  <div className="row justify-content-end">
                    <div className="arrowbg text-center py-2">
                      <i className="fa fa-arrow-right text-light" aria-hidden="true"></i>
                    </div>
                  </div>
                </div>
              </a>
            </div>

            <div className="col-lg-4 col-md-6 col-12 mb-4">
              <a href="https://gzk.rks-gov.net/ActDetail.aspx?ActID=3702" target="_blank" rel="noopener noreferrer" className="card-link">
                <div className="shadow p-4">
                  <h5 className="card-title">Ustav Serbska</h5>
                  <div className="card-body text-single">
                    <p>
                      <span>Tip akta: Ustav</span><br />
                      <span>Akt broj: K-09042008</span><br />
                      <span>Institucija: Skupština Republike Kosovo</span>
                    </p>
                  </div>
                  <div className="row justify-content-end">
                    <div className="arrowbg text-center py-2">
                      <i className="fa fa-arrow-right text-light" aria-hidden="true"></i>
                    </div>
                  </div>
                </div>
              </a>
            </div>
          </div>

          <div className="row mt-5">
            <h1 className="mb-5 text-center">Strategjitë</h1>

            <div className="col-lg-3 col-md-6 col-12 mb-4">
              <a href={Pdf_shqip} target="_blank" rel="noopener noreferrer" className="card-link">
                <div className="shadow p-4">
                  <h5 className="card-title">Strategjia Shqip</h5>
                  <p className="card-text">Strategjia për avancimin e të drejtave të komuniteteve...</p>
                  <div className="row justify-content-end">
                    <div className="arrowbg text-center py-2">
                      <i className="fa fa-arrow-right text-light" aria-hidden="true"></i>
                    </div>
                  </div>
                </div>
              </a>
            </div>

            <div className="col-lg-3 col-md-6 col-12 mb-4">
              <a href={Pdf_eng} target="_blank" rel="noopener noreferrer" className="card-link">
                <div className="shadow p-4">
                  <h5 className="card-title">Strategy English</h5>
                  <p className="card-text">Strategy for the advancement of Roma and Ashkali communities...</p>
                  <div className="row justify-content-end">
                    <div className="arrowbg text-center py-2">
                      <i className="fa fa-arrow-right text-light" aria-hidden="true"></i>
                    </div>
                  </div>
                </div>
              </a>
            </div>

            <div className="col-lg-3 col-md-6 col-12 mb-4">
              <a href={Pdf_srb} target="_blank" rel="noopener noreferrer" className="card-link">
                <div className="shadow p-4">
                  <h5 className="card-title">Strategija Serbska</h5>
                  <p className="card-text">Strategija za unapređenje prava zajednica Roma i Aškalija...</p>
                  <div className="row justify-content-end">
                    <div className="arrowbg text-center py-2">
                      <i className="fa fa-arrow-right text-light" aria-hidden="true"></i>
                    </div>
                  </div>
                </div>
              </a>
            </div>

            <div className="col-lg-3 col-md-6 col-12 mb-4">
              <a href={Pdf_rome} target="_blank" rel="noopener noreferrer" className="card-link">
                <div className="shadow p-4">
                  <h5 className="card-title">Strategia Romani</h5>
                  <p className="card-text">Strategia vash avancipe e hakayengo e Romane thaj Ashkalyune...</p>
                  <div className="row justify-content-end">
                    <div className="arrowbg text-center py-2">
                      <i className="fa fa-arrow-right text-light" aria-hidden="true"></i>
                    </div>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Constitution;
