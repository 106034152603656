import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "../../axios";
import "./Aboutus.scss";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";
import { setLangData, setLangID } from "../../redux/Functions/actions";
import aboutUsImg from "./../Slider/images/SliderImage3.jpg";
const About = (props) => {
  const [lawsHome, setLawsHome] = useState([]);
  const location = useLocation();
  const { pathname } = location;
  const splitLocation = pathname.split("/");
  const translateMe = (key, langId) => {
    let diqka = props?.langData?.filter((el) => el.key == key)[0];
    if (langId === "al") {
      return diqka?.content_al;
    }
    if (langId === "sr") {
      return diqka?.content_sr;
    }
    if (langId === "en") {
      return diqka?.content_en;
    }
  };

  useEffect(() => {
    let isMounted = true;
    axios
      .get("/general/aboutus")
      .then((res) => {
        if (isMounted) {
          setLawsHome(res?.data?.slice(0, 3));
        }
      })
      .catch((err) => console.log("error", err));
    return () => {
      isMounted = false;
    };
  }, []);

  return (
<div className="container my-5">
  <div className="about-us-card row">
    <div className="card-image col-md-7 col-12 px-md-0">
      <img src={aboutUsImg} alt="About Us" className="img-fluid" />
    </div>
    <div className="col-md-6 col-12 d-flex flex-column justify-content-center align-items-center">
      <Link className="card-content text-center" to="/aboutus">
        <h1 className="text-center text-dark text-uppercase mb-4">
          {translateMe("mainMenuAboutUs", props?.landId)}
        </h1>
        {lawsHome?.map((e, index) => {
          let content;
          if (props?.landId === "al") content = e.content_al;
          else if (props?.landId === "en") content = e.content_en;
          else content = e.content_sr;

          return (
            <div key={index} className="mb-4">
              <p
                className="text-dark mb-4 fw-lighter"
                dangerouslySetInnerHTML={{
                  __html: content.slice(0, 260) + "...",
                }}
              ></p>
              <div className="row justify-content-center">
                <div className="col-6">
                  <div className="border-top border-warning"></div>
                </div>
              </div>
            </div>
          );
        })}
      </Link>
    </div>
  </div>
</div>

  );
};

const mapStateToProps = (state) => {
  return {
    landId: state.data.langId,
    langData: state.data.langData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setLangID: (id) => dispatch(setLangID(id)),
    setLangData: (data) => dispatch(setLangData(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(About);
