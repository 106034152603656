import React, { useState, useEffect } from "react";
import Untitled1 from "./imagesM/Home.png";
import Untitled2 from "./imagesM/register.png";
import Untitled3 from "./imagesM/loginn.png";
import Untitled4 from "./imagesM/ReportButton.png";
import Untitled5 from "./imagesM/report.png";
import Untitled6 from "./imagesM/ReportMeLoginRegister.png";
import Untitled9 from "./imagesM/profile.png";
import Untitled10 from "./imagesM/chat.png";
import Loader from "../loader/loader";
import "./Manuals.scss";
import { connect } from "react-redux";

const Manuals = (propss) => {
    const [isLoading, setLoading] = useState(true);

    const translateMe = (key, langId) => {
        let translation = propss?.langData?.filter((el) => el.key === key)[0];
        if (langId === "al") return translation?.content_al;
        if (langId === "sr") return translation?.content_sr;
        if (langId === "en") return translation?.content_en;
    };

    useEffect(() => {
        setLoading(false);
    }, []);

    if (isLoading) {
        return <Loader />;
    }

    return (
        <div className="manuals-page">
            <div className="container">
                <h2 className="text-center my-5 title">
                    {translateMe("stepsOfUsingTheApp", propss?.landId)}
                </h2>

                <div className="row">
                    {/* Step 1 */}
                    <div className="col-md-6 mb-4">
                        <div className="manual-step card">
                            <h5 className="step-title">
                                <b>{translateMe("step", propss?.landId)} 1:</b> {translateMe("firstStep", propss?.landId)}
                            </h5>
                            <img className="img-fluid step-image" src={Untitled1} alt="Step 1" />
                        </div>
                    </div>

                    {/* Step 2 */}
                    <div className="col-md-6 mb-4">
                        <div className="manual-step card">
                            <h5 className="step-title">
                                <b>{translateMe("step", propss?.landId)} 2:</b> {translateMe("secondStep", propss?.landId)}
                            </h5>
                            <img className="img-fluid step-image" src={Untitled2} alt="Step 2" />
                        </div>
                    </div>

                    {/* Step 3 */}
                    <div className="col-md-6 mb-4">
                        <div className="manual-step card">
                            <h5 className="step-title">
                                <b>{translateMe("step", propss?.landId)} 3:</b> {translateMe("thirdStep", propss?.landId)}
                            </h5>
                            <img className="img-fluid step-image" src={Untitled3} alt="Step 3" />
                        </div>
                    </div>

                    {/* Step 4 */}
                    <div className="col-md-6 mb-4">
                        <div className="manual-step card">
                            <h5 className="step-title">
                                <b>{translateMe("step", propss?.landId)} 4:</b> {translateMe("fourthStep", propss?.landId)}
                            </h5>
                            <img className="img-fluid step-image" src={Untitled4} alt="Step 4" />
                        </div>
                    </div>

                    {/* Step 5 */}
                    <div className="col-md-6 mb-4">
                        <div className="manual-step card">
                            <h5 className="step-title">
                                <b>{translateMe("step", propss?.landId)} 5:</b> {translateMe("fifthStep", propss?.landId)}
                            </h5>
                            <img className="img-fluid step-image" src={Untitled5} alt="Step 5" />
                        </div>
                    </div>

                    {/* Step 6 */}
                    <div className="col-md-6 mb-4">
                        <div className="manual-step card">
                            <h5 className="step-title">
                                <b>{translateMe("step", propss?.landId)} 6:</b> {translateMe("sixthStep", propss?.landId)}
                            </h5>
                            <img className="img-fluid step-image" src={Untitled6} alt="Step 6" />
                        </div>
                    </div>

                    {/* Step 7 */}
                    <div className="col-md-6 mb-4">
                        <div className="manual-step card">
                            <h5 className="step-title">
                                <b>{translateMe("step", propss?.landId)} 7:</b> {translateMe("seventhStep", propss?.landId)}
                            </h5>
                            <img className="img-fluid step-image" src={Untitled9} alt="Step 7" />
                        </div>
                    </div>

                    {/* Step 8 */}
                    <div className="col-md-6 mb-4">
                        <div className="manual-step card">
                            <h5 className="step-title">
                                <b>{translateMe("step", propss?.landId)} 8:</b> {translateMe("eighthStep", propss?.landId)}
                            </h5>
                            <img className="img-fluid step-image" src={Untitled10} alt="Step 8" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = (state) => ({
    landId: state.data.langId,
    langData: state.data.langData,
});

export default connect(mapStateToProps)(Manuals);
