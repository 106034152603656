import React from "react";
import "./CustomSlider.scss";
import { Link } from "react-router-dom";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";

// Import images for the slider
import image2 from "./images/SliderImage2.jpg";
import image3 from "./images/SliderImage4.jpg";
import image1 from "./images/SliderImage.jpg";
import googleStore from "./images/app-store-badge.png";
import appStore from "./images/google-play-badge.png";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";
import { Slide } from "react-toastify";
// const  Slider (props)  = (props) => {
const CustomSlider = (props) => {
  const translateMe = (key, langId) => {
    let diqka = props?.langData?.filter((el) => el?.key == key)[0];
    if (langId === "al") {
      return diqka?.content_al;
    }
    if (langId === "sr") {
      return diqka?.content_sr;
    }
    if (langId === "en") {
      return diqka?.content_en;
    }
  };
  const location = useLocation();
  const { pathname } = location;
  const splitLocation = pathname?.split("/");
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 300,
    arrows: false,
  };
  const highlightFirstCapitalizedWord = (text) => {
    const words = text?.split(" ");

    const highlightedText = words?.map((word, index) => {
      if (index === 0 || word?.charAt(0) === word?.charAt(0)?.toUpperCase()) {
        return `<span class="highlight">${word}</span>`;
      }
      return word; 
    });

    return highlightedText?.join(" ");
  };
  return (
    <div className="custom-slider">
      <Carousel {...settings}>
        <div className="slide" style={{ backgroundImage: `url(${image1})` }}>
          <div className="slide-content row justify-content-center">
            <div className="col-md-7 col-12">
              {/* <p className="slider-title">
            <span className="highlight"> Platforma kombëtare </span> për mbrojtje nga diskriminimi për komunitetet
              <span className="highlight"> Rom</span>, <br class="d-md-block"/>     <span className="highlight">Ashkali </span>
              dhe <span className="highlight">Egjiptian</span>
            </p> */}
                <p
                  className="slider-title my-0"
                  dangerouslySetInnerHTML={{
                    __html: highlightFirstCapitalizedWord(
                      translateMe("sliderText", props?.landId)
                    ),
                  }}
                />
              <div className="store-buttons">
                <img src={googleStore} alt="Google Play" />
                <img src={appStore} alt="App Store" />
              </div>
            </div>
          </div>
        </div>
        <div className="slide" style={{ backgroundImage: `url(${image2})` }}>
          <div className="slide-content row justify-content-center">
            <div className="col-md-7 col-12">
              <p className="slider-title">
                <span className="highlight"> Platforma kombëtare </span> për
                mbrojtje nga diskriminimi për komunitetet
                <span className="highlight"> Rom</span>,{" "}
                <br class="d-md-block" />{" "}
                <span className="highlight">Ashkali </span>
                dhe <span className="highlight">Egjiptian</span>
              </p>
              <div className="store-buttons">
                <img src={googleStore} alt="Google Play" />
                <img src={appStore} alt="App Store" />
              </div>
            </div>
          </div>
        </div>
        <div className="slide" style={{ backgroundImage: `url(${image3})` }}>
          <div className="slide-content row justify-content-center">
            <div className="col-md-7 col-12">
              <p className="slider-title">
                <span className="highlight"> Platforma kombëtare </span> për
                mbrojtje nga diskriminimi për komunitetet
                <span className="highlight"> Rom</span>,{" "}
                <br class="d-md-block" />{" "}
                <span className="highlight">Ashkali </span>
                dhe <span className="highlight">Egjiptian</span>
              </p>
              <div className="store-buttons">
                <img src={googleStore} alt="Google Play" />
                <img src={appStore} alt="App Store" />
              </div>
            </div>
          </div>
        </div>
      </Carousel>

      <div className="container">
        <div className="row justify-content-center manual-report-main">
          <div className="manual-report-section col-lg-8 col-md-9 px-0">
            <div className="manual half left-half">
              <Link to="/manuals" className="manual-button">
                <div className="content">
                  <i className="fa fa-file-text-o" aria-hidden="true"></i>
                  <div className="text-content">
                    <h4>MANUALET</h4>
                    <h6 className="fw-lighter">E PUBLIKUARA</h6>
                  </div>
                </div>
              </Link>
            </div>
            <div className="report half right-half">
              <Link to="/report" className="report-button">
                <div className="content">
                  <div className="text-content">
                    <h4>RAPORTO</h4>
                    <h6 className="fw-lighter">NË PLATFORMË</h6>
                  </div>
                  <i
                    className="fa fa-arrow-right fw-lighter"
                    aria-hidden="true"
                  ></i>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    landId: state.data.langId,
    langData: state.data.langData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomSlider);
